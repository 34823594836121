import React, { Component } from "react";

export default class Wall extends Component {
  render() {
    const { tex, isPrev } = this.props;
    if (!tex) {
      return null;
    }
    const backImg = require(`../../assets/textures/${tex.name}`);
    return (
      <div className={`${tex.wall} ${!isPrev && "fade"}`}>
        <style jsx>{`
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          div {
            background-image: url(${backImg});
            background-size: 100% auto;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 0;
            pointer-events: none;
          }
          .fade {
            animation: 5s ease-in-out 0s fadeIn;
            z-index: 1;
          }
          .top {
          }
          .right {
            top: -1px;
          }
          .bottom {
          }
          .left {
          }
        `}</style>
      </div>
    );
  }
}
