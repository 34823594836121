import React, { useState, useEffect } from 'react';
import PageContent from './PageContent';

const Page = ({
  triggerRestart,
  homeOut,
  restart,
  onAssetsLoaded,
  showCartelButtons,
}) => {
  const [moduleVisible, setModuleVisible] = useState(false);

  useEffect(() => {
    if (homeOut) {
      setModuleVisible(true);
    }
  }, [homeOut]);

  useEffect(() => {
    // if (process.env.NODE_ENV !== 'production') {
    onAssetsLoaded();
    // }
    /*
    const channel = new BroadcastChannel('sw-messages');
    channel.addEventListener('message', (event) => {
      onAssetsLoaded();
    });*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (triggerRestart === true) {
      restart();

      setTimeout(() => {
        setModuleVisible(false);
      }, 900);
    }
  }, [restart, triggerRestart]);

  if (moduleVisible === false) return null;

  return <PageContent homeOut={homeOut} />;
};

export default Page;
