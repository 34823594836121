import React from 'react';

const Switch = ({ switchSeason, isSummer }) => {
  return (
    <button
      onClick={switchSeason}
      className={`withAction ${isSummer && 'isSummer'}`}
    >
      {!isSummer ? (
        <img
          className='picto'
          src={require(`../../assets/buttons/icon_summer.png`)}
          alt=''
        />
      ) : (
        <img
          className='picto'
          src={require(`../../assets/buttons/icon_winter.png`)}
          alt=''
        />
      )}
      <span className='arrow'>
        <img
          className={`arrowImg`}
          src={
            isSummer
              ? require(`../../assets/buttons/NM_Pictogram_Digital_ArrowRight.svg`)
              : require(`../../assets/buttons/NM_Pictogram_Digital_ArrowLeft.svg`)
          }
          alt=''
        />
      </span>

      <style jsx>{`
        button {
          position: absolute;
          top: 50px;
          left: 50px;
          z-index: 10;
          background: white;
          border-radius: 80px;
          text-align: left;
          padding: 10px;
          transition: 0.2s transform ease-out, 0.7s opacity ease-out;
          user-select: none;
          width: 133px;
          height: 65px;
        }
        .picto,
        .arrow {
          display: inline-block;
          vertical-align: top;
          width: 50px;
          position: absolute;
          top: 7px;
          left: 9px;
        }
        .arrow {
          left: 73px;
          border: 1px solid #d1d1d1;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
        }
        .arrowImg {
          width: 20px;
        }

        .isSummer .arrow {
          left: 9px;
        }
        .isSummer .picto {
          left: 74px;
        }
      `}</style>
    </button>
  );
};

export default Switch;
