import React from 'react';
import DecorationDog from '../DecorationDog/DecorationDog';

//TODO Ajouter le poisson

const Decorations = ({ isSummer }) => {
  return (
    <div className='container'>
      <video
        className={`${isSummer ? 'active' : 'inactive'} window`}
        src={require(`../../assets/decoration/window_summer_loop.mp4`)}
        loop
        autoPlay
      />
      <video
        className={`${!isSummer ? 'active' : 'inactive'} window`}
        src={require(`../../assets/decoration/window_loop.mp4`)}
        loop
        autoPlay
      />

      <DecorationDog
        key='dog-1'
        type='dog'
        video={'dog_cache.mp4'}
        width='250'
        height='220'
      />
      <DecorationDog
        key='woman-1'
        type='woman'
        video={'woman.mp4'}
        width='400'
        height='300'
      />
      <DecorationDog
        key='fish-1'
        type='fish'
        video={'fish.mp4'}
        width='80'
        height='35'
      />
      <style jsx>{`
        .container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
        }
        .window {
          position: absolute;
          width: 801px;
          left: 408.1px;
          top: 113px;
          height: 400px;
          opacity: 0;
          transition: opacity 1s;
        }
        .window.active {
          z-index: 1;
          opacity: 1;
        }
        .window.inactive {
          transition-delay: 1s;
          opacity: 0;
        }
        .woman {
          position: absolute;
          width: 322px;
          left: 678px;
          top: 419px;
        }
      `}</style>
    </div>
  );
};

export default Decorations;
