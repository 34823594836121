import React, { Component } from 'react';

export default class Point extends Component {
  render() {
    const { dir, dirId, color, onOpenPalette, paletteOpened, paletteSideOn } =
      this.props;
    return (
      <div
        className={`container ${dir}`}
        onClick={() => {
          onOpenPalette(dirId);
        }}
      >
        <button
          className={`withAction   ${
            paletteOpened && paletteSideOn !== dirId && 'hide'
          }  ${paletteOpened && paletteSideOn === dirId && 'jump'}`}
        >
          <span style={{ backgroundColor: `#${color.code}` }} />
        </button>
        <style jsx>{`
          @keyframes jumpIn {
            0% {
              transform: translate3d(0, 0px, 0);
            }
            50% {
              transform: translate3d(0, -10px, 0);
            }
            100% {
              transform: translate3d(0, 0px, 0);
            }
          }

          .container {
            position: absolute;
            width: 63px;
            height: 75px;

            opacity: 0;
            animation: 0.9s fade-in forwards ease-in-out;
            animation-delay: ${3 + dirId * 0.2}s;
            z-index: 4;
          }

          button {
            position: absolute;
            width: 63px;
            height: 75px;
            background-image: url('${require('../../assets/buttons/icon@2x.png')}');
            background-size: 100% auto;
            background-repeat: no-repeat;
            top: 0;
            left: 0;
            transition: 0.2s transform ease-out, 0.7s opacity ease-out;

            transform: translate3d(0, 0px, 0);
          }

          button.hide {
            opacity: 0;
            transition: 0.4s transform ease-out, 0.4s opacity ease-out;
            transform: translate3d(0, 5px, 0);
          }

          button.jump {
            // animation: jumpIn 1.7s infinite ease-in-out;
          }

          span {
            position: absolute;
            top: 4px;
            left: 4px;
            width: 55px;
            height: 55px;
            border-radius: 100%;
            transition: 0.7s background-color ease-out;
          }
          .top {
            top: 5%;
            left: 40%;
          }
          .right {
            left: 79%;
            top: 20%;
          }
          .back {
            top: 57%;
            left: 28%;
          }
          .left {
            top: 13%;
            left: 15%;
          }

          span.pink {
            background-color: #763d34;
          }
          span.grey {
            background-color: #9da295;
          }
          span.green {
            background-color: #649a8b;
          }
          span.blue {
            background-color: #a0cdd8;
          }

          @keyframes fade-in {
            from {
              opacity: 0;
              transform: translate3d(0, -10px, 0);
            }

            to {
              opacity: 1;
              transform: translate3d(0, 0px, 0);
            }
          }
        `}</style>
      </div>
    );
  }
}
