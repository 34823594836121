import React, { useEffect, useState } from 'react';
import { easings } from 'oslo-ui';
import Walls from '../Walls/Walls';
const { easeInOutQuart } = easings;

const PageContent = ({ homeOut }) => {
  const [animateIn, setAnimateIn] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  useEffect(() => {
    if (homeOut === true) {
      setAnimateIn(true);
      setAnimateOut(false);
      //setSceneVisible(true);
    }

    if (homeOut === false) {
      setAnimateIn(false);
      setAnimateOut(true);
    }
  }, [homeOut]);
  return (
    <div
      className={`container ${animateIn ? 'in' : ''} ${
        animateOut ? 'out' : ''
      }`}
    >
      <Walls />
      <style jsx>{`
        .container {
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: translate3d(0, 100%, 0);
          transition: transform 1.5s ${easeInOutQuart};
          z-index: -1;
        }

        .container.in {
          transform: translate3d(0, 0, 0);
        }

        .container.out {
          transform: translate3d(0, 100%, 0);
        }
      `}</style>
    </div>
  );
};

export default PageContent;
