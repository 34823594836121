import React from 'react';

function PaletteClose({ onClosePalette }) {
  return (
    <button onClick={onClosePalette}>
      <style jsx>{`
        button {
          position: absolute;
          top: 50%;
          right: 20px;
          width: 90px;
          height: 90px;
          transform: translateX(-50%) translateY(-50%);
          background: white;
          border-radius: 100%;
          z-index: 2;
          transition: 0.2s transform ease-out;
          outline: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: url('${require('../../assets/buttons/close.svg')}');
          background-repeat: no-repeat;
          background-position: center center;
          pointer-events: auto;
        }
        button:active {
          transform: translateX(-50%) translateY(-50%) scale3d(0.9, 0.9, 1);
        }
      `}</style>
    </button>
  );
}

export default PaletteClose;
