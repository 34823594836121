const colors = [
  {
    name: "",
    code: "c9d7e1"
  },
  {
    name: "",
    code: "9bc2e5"
  },
  {
    name: "",
    code: "3b7c98"
  },
  {
    name: "",
    code: "0c5a8c"
  },
  {
    name: "",
    code: "363d54"
  },
  {
    name: "",
    code: "cedbbe"
  },
  {
    name: "",
    code: "9aa792"
  },
  {
    name: "",
    code: "959b75"
  },
  {
    name: "",
    code: "92b697"
  },
  {
    name: "",
    code: "4e6747"
  },
  {
    name: "",
    code: "2e463b"
  },
  {
    name: "",
    code: "60614e"
  },
  {
    name: "",
    code: "7b6839"
  },
  {
    name: "",
    code: "ad8e4d"
  },
  {
    name: "",
    code: "d49349"
  },
  {
    name: "",
    code: "e6a400"
  },
  {
    name: "",
    code: "f0b042"
  },
  {
    name: "",
    code: "bb6c2e"
  },
  {
    name: "",
    code: "c28866"
  },
  {
    name: "",
    code: "bd705d"
  },
  {
    name: "",
    code: "9e3031"
  },
  {
    name: "",
    code: "8d1217"
  },
  {
    name: "",
    code: "dab8b7"
  },
  {
    name: "",
    code: "f3e1cd"
  },
  {
    name: "",
    code: "d2bfb4"
  },
  {
    name: "",
    code: "332f37"
  },
  {
    name: "",
    code: "0d0d0d"
  },
  {
    name: "",
    code: "fef9f8"
  }
];

const directions = ["top", "right", "back", "left"];

const textures = [];

for (let i = 1; i <= 28; i++) {
  textures.push({
    name: `top/plafond_${i}.png`,
    wall: directions[0],
    color: colors[i - 1]
  });
}

for (let i = 1; i <= 28; i++) {
  textures.push({
    name: `right/droite_${i}.png`,
    wall: directions[1],
    color: colors[i - 1]
  });
}

for (let i = 1; i <= 28; i++) {
  textures.push({
    name: `back/fond_${i}.png`,
    wall: directions[2],
    color: colors[i - 1]
  });
}

for (let i = 1; i <= 28; i++) {
  textures.push({
    name: `left/gauche_${i}.png`,
    wall: directions[3],
    color: colors[i - 1]
  });
}

export { colors, textures, directions };
