import React, { Component } from 'react';
import { colors, textures, directions } from './Textures';
import Wall from '../Wall/Wall';
import Point from '../Point/Point';
import Palette from '../Palette/Palette';
import Decorations from '../Decorations/Decorations';
import WallGl from '../WallGl/WallGl';
import Switch from '../Switch/Switch';

export default class Walls extends Component {
  constructor(props) {
    super(props);
    //console.log("colors", colors);
    //console.log("textures", textures);
    this.assetsPath = `../../assets/textures/`;

    const activeColors = [colors[22], colors[19], colors[5], colors[18]];
    this.state = {
      activeColors: activeColors,
      paletteOpened: false,
      paletteSideOn: -1,
      prevWall: null,
      progress: 0,
      isSummer: false,
    };
  }

  getWallsBy = (dir) => {
    return textures.filter((e, i) => e.wall === dir);
  };

  getWall = (dir) => {
    const wallData = {
      dir: directions[dir],
      color: this.state.activeColors[dir],
    };
    const wallTex = textures.filter(
      (e, i) => e.wall === wallData.dir && e.color === wallData.color
    )[0];

    //const wallTex = textures[0];

    return (
      <Wall
        key={dir}
        tex={wallTex}
        assetsPath={this.assetsPath}
        dir={directions[dir]}
      />
    );
  };

  getWalls = () => {
    return [this.getWall(0), this.getWall(1), this.getWall(2), this.getWall(3)];
  };

  setPrevWall = (prevActiveColor, paletteSideOn) => {
    const wallData = {
      dir: directions[paletteSideOn],
      color: prevActiveColor,
    };
    const wallTex = textures.filter(
      (e, i) => e.wall === wallData.dir && e.color === wallData.color
    )[0];

    this.setState({
      prevWall: { wallTex: wallTex, dir: directions[paletteSideOn] },
    });
  };

  getPrevWall = () => {
    const { prevWall } = this.state;
    if (!prevWall) return null;
    return (
      <Wall
        key={prevWall.dir + 4}
        tex={prevWall.wallTex}
        isPrev
        assetsPath={this.assetsPath}
        dir={directions[prevWall.dir]}
      />
    );
  };

  getPoint = (dir) => {
    const { paletteOpened, paletteSideOn } = this.state;
    return (
      <Point
        key={dir}
        dirId={dir}
        dir={directions[dir]}
        color={this.state.activeColors[dir]}
        onOpenPalette={this.openPalette}
        paletteOpened={paletteOpened}
        paletteSideOn={paletteSideOn}
      />
    );
  };

  getPoints = () => {
    return [
      this.getPoint(0),
      this.getPoint(1),
      this.getPoint(2),
      this.getPoint(3),
    ];
  };

  openPalette = (dir) => {
    this.setState({ paletteOpened: true, paletteSideOn: dir });
  };

  closePalette = () => {
    this.setState({ paletteOpened: false });
  };

  onColor = (color) => {
    /*const getRandomInt = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min);
    };*/

    this.closePalette();
    //if (this.state.paletteSideOn !== 0) {
    let { activeColors } = this.state;
    const prevActiveColor = activeColors[this.state.paletteSideOn];

    //if (this.state.paletteSideOn === 3 || this.state.paletteSideOn === 2) {
    const idColor = colors.findIndex((e) => e.code === color.code);
    activeColors[this.state.paletteSideOn] = colors[idColor];
    //}
    /*else {
        activeColors[this.state.paletteSideOn] = colors[getRandomInt(0, 3)];
      }*/

    this.setState({ activeColors });
    this.setPrevWall(prevActiveColor, this.state.paletteSideOn);
    //}
  };

  render() {
    const moduleVisible = true;
    const { activeColors, paletteOpened, paletteSideOn, isSummer } = this.state;

    // Top Right Back Left
    const walls = this.getWalls();
    const points = this.getPoints();
    //const prevWall = this.getPrevWall();

    const topWalls = this.getWallsBy('top');
    const rightWalls = this.getWallsBy('right');
    const backWalls = this.getWallsBy('back');
    const leftWalls = this.getWallsBy('left');

    return (
      <div className='container'>
        <div className='darkFadeIn' />
        <Switch
          isSummer={isSummer}
          switchSeason={() => {
            this.setState({ isSummer: !isSummer });
          }}
        />
        <img
          className='fakeChair'
          src={require('../../assets/decoration/fake_chair.jpg')}
          alt=''
        />
        <img
          className='fakeRight'
          src={require('../../assets/decoration/fake_right.png')}
          alt=''
        />
        <WallGl
          walls={topWalls}
          assetsPath={this.assetsPath}
          dir={'top'}
          activeColor={activeColors[0]}
          animDir={[0, -1]}
          isReady={moduleVisible}
        />
        <WallGl
          walls={rightWalls}
          assetsPath={this.assetsPath}
          dir={'right'}
          activeColor={activeColors[1]}
          animDir={[-1, 0]}
          isReady={moduleVisible}
        />
        <WallGl
          walls={backWalls}
          assetsPath={this.assetsPath}
          dir={'back'}
          activeColor={activeColors[2]}
          animDir={[0, -1]}
          isReady={moduleVisible}
        />
        <WallGl
          walls={leftWalls}
          assetsPath={this.assetsPath}
          dir={'left'}
          activeColor={activeColors[3]}
          animDir={[1, 0]}
          isReady={moduleVisible}
        />
        {moduleVisible && walls}
        {moduleVisible && points}
        {moduleVisible && <Decorations isSummer={isSummer} />}
        {moduleVisible && (
          <Palette
            colors={colors}
            activeColor={
              paletteSideOn !== -1 ? activeColors[paletteSideOn] : null
            }
            opened={paletteOpened}
            onClosePalette={this.closePalette}
            onColor={this.onColor}
            paletteSideOn={paletteSideOn}
          />
        )}
        <style jsx>{`
          .container {
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
          }

          .darkFadeIn {
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 15;
            animation: fade-out 0.9s 0.4s forwards ease-in-out;
            pointer-events: none;
          }
          .fakeChair {
            position: absolute;
            left: 346px;
            top: 642px;
            width: 36px;
            z-index: 3;
            user-select: none;
          }
          .fakeRight {
            position: absolute;
            left: 1214.8px;
            top: 584px;
            width: 6.7px;
            z-index: 3;
            user-select: none;
          }

          @keyframes fade-out {
            0% {
              opacity: 1;
            }

            100% {
              opacity: 0;
            }
          }
        `}</style>
      </div>
    );
  }
}
