import React from 'react';

import Page from '../../components/Page/Page';
import { OsloUI } from 'oslo-ui';
import { importTransAssets } from '../../utils/assetFetcher';

class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      lang: 'no',
      moduleData: undefined,
      transversalData: undefined,
    };
  }

  componentDidMount = () => {
    //this.precache();
    this.updateLang(this.state.lang);
  };
  /*
  precache = () => {
    const req = require.context(
      '../../../public/assets/',
      true,
      /\.(png|gif|jpe?g|svg|mp4|ico)$/
    );
    req.keys().forEach(function (key) {
      const name = req(key);
      console.log('name', key, name);
    });
  };*/

  isObject = (item) => {
    return item && typeof item === 'object' && !Array.isArray(item);
  };

  mergeDeep = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  };

  updateLang = (langCode) => {
    const transversalData = importTransAssets(
      this.mergeDeep(
        require(`../../static/data/${langCode}/transversal.json`),
        require(`../../static/data/transverse_data.json`)
      ),
      [
        ['home', 'background'],
        ['audioSubLang', 'backgroundImage'],
        ['informations', 'background'],
        ['tutorial', 'animation', 'imgSprite'],
        ['help', 'background'],
        ['help', 'tutorial', 'animation', 'imgSprite'],
        ['credits', 'backgroundImage'],
      ]
    );
    this.setState({
      lang: langCode,
      moduleData: {
        ...require(`../../static/data/${langCode}/data.json`),
        ...require(`../../static/data/module_data.json`),
      },
      transversalData,
    });
  };

  render = () => {
    const { lang, transversalData, moduleData } = this.state;

    if (
      this.state.moduleData === undefined ||
      this.state.transversalData === undefined
    ) {
      return null;
    }
    return (
      <div className='home'>
        <OsloUI
          Child={Page}
          moduleData={moduleData}
          data={transversalData}
          updateLang={this.updateLang}
          lang={lang}
        />
        <style jsx global>
          {`
            body {
              margin: 0;
              overflow: hidden;
              touch-action: pan-x pan-y;
            }

            body::-webkit-scrollbar {
              width: 0 !important;
            }
            button {
              padding: 0;
              margin: 0;
              outline: 0;
              background: none;
              border: 0;
              cursor: pointer;
            }
            button.withAction {
              padding: 0;
              margin: 0;
              outline: 0;
              background: none;
              border: 0;
              cursor: pointer;
            }
            button.withAction:active {
              transform: scale3d(0.9, 0.9, 1);
            }
            button {
              -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              -webkit-tap-highlight-color: transparent;
            }
          `}
        </style>
      </div>
    );
  };
}

export default App;
