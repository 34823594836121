import React, { Component } from 'react';
import PaletteColor from '../PaletteColor/PaletteColor';
import { gsap, TweenLite } from 'gsap';
import Draggable from 'gsap/Draggable';
import PaletteClose from '../PaletteClose/PaletteClose';
import PaletteBack from '../PaletteBack/PaletteBack';
import InertiaPlugin from '../../utils/InertiaPlugin';

gsap.registerPlugin(InertiaPlugin, Draggable);

export default class Palette extends Component {
  constructor(props) {
    super(props);
    this.draggableWheel = null;
    this.state = { wheeling: false, angle: 0 };
  }
  componentDidMount() {
    let _this = this;
    this.draggableWheel = Draggable.create(this.wheel, {
      type: 'rotation',
      inertia: true,
      throwProps: true,
      dragClickables: true,
      onDrag: () => {
        _this.setState({ angle: this.draggableWheel[0].rotation });
      },
      onThrowUpdate: () => {
        _this.setState({ angle: this.draggableWheel[0].rotation });
      },
    });
  }

  rollToActive = () => {
    const { colors, activeColor } = this.props;

    const activePoint = colors.findIndex((e, i) => e.code === activeColor.code);
    const total = colors.length;
    const angle = -90 + -((360 / total) * activePoint);

    TweenLite.fromTo(
      this.wheel,
      0,
      { rotation: 0 },
      {
        rotation: angle,
      }
    );

    this.setState({ angle: angle });
  };

  componentDidUpdate(prevProps) {
    if (this.props.opened && !prevProps.opened) {
      this.rollToActive();
    }
  }

  onColor = (e) => {
    this.props.onColor(e);
    this.setState({ angle: this.draggableWheel[0].rotation });
  };

  render() {
    const { colors, activeColor, opened, onClosePalette } = this.props;
    const { wheeling, angle } = this.state;

    const paletteColors = colors.map((e, i) => (
      <PaletteColor
        key={i}
        i={i}
        color={e}
        totalColors={colors.length}
        activeColor={activeColor}
        onColor={this.onColor}
        wheel={this.draggableWheel}
        wheeling={wheeling}
        angle={activeColor && activeColor.code === e.code ? angle : 0}
      />
    ));
    return (
      <div className={`container ${opened && 'opened'}`}>
        <PaletteBack opened={opened} />
        <div className='wheelContainer'>
          <PaletteBack
            opened={opened}
            transparent
            onClosePalette={onClosePalette}
          />
          <div className='wheel' ref={(e) => (this.wheel = e)}>
            {paletteColors}
          </div>
          <PaletteClose onClosePalette={onClosePalette} />
        </div>

        <style jsx>{`
          .container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            pointer-events: none;
            overflow: hidden;
          }

          .container.opened {
            pointer-events: auto;
          }

          .wheelContainer {
            z-index: 2;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            transform: translateX(385px);
            transition: 0.6s transform ease-in-out;
          }
          .opened .wheelContainer {
            transform: translateX(0px);
          }

          .wheel {
            position: absolute;
            width: 700px;
            height: 700px;
            right: -432px;
            top: 50%;
            z-index: 2;
            transform: translateY(-50%);
          }
        `}</style>
      </div>
    );
  }
}
