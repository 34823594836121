import React, { Component } from 'react';
import { TweenLite } from 'gsap';

export default class PaletteColor extends Component {
  componentDidMount() {
    const border = 0;
    const radius = (Math.min(700, 700) * 1) / 2;
    const center = radius - border / 2;
    const total = this.props.totalColors;
    const slice = (2 * Math.PI) / total;

    const i = this.props.i;
    //  var num = i + 1;
    //var hue = (i / total) * 360;
    const angle = i * slice;
    const x = center + radius * Math.sin(angle);
    const y = center - radius * Math.cos(angle);

    const box = this.container;
    TweenLite.set(box, {
      //rotation: angle + "_rad",
      xPercent: -50,
      yPercent: -50,
      x: x,
      y: y,
    });
  }
  render() {
    const { color, activeColor, onColor, angle } = this.props;
    const checkImg = require('../../assets/buttons/check.svg');
    return (
      <button
        className={`${
          activeColor && activeColor.code === color.code && 'active'
        }`}
        ref={(e) => (this.container = e)}
        onClick={() => {
          onColor(color);
        }}
      >
        <div>
          <span />
        </div>

        <style jsx>{`
          button {
            width: 56px;
            height: 56px;
            position: absolute;
          }

          button.active span {
            width: 56px;
            height: 56px;
            top: 0;
            left: 0;
            background-image: url('${checkImg}');
            background-repeat: no-repeat;
            background-size: 18px auto;
            background-position: center 23px;
            transform: rotate(${-angle}deg) scale3d(1.3, 1.3, 1);
          }

          div {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 56px;
            height: 56px;
            border-radius: 100%;
            background: white;
            transition: 0.2s transform ease-out;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          button:active div {
            transform: scale3d(0.9, 0.9, 1);
          }
          span {
            width: 50px;
            height: 50px;
            display: block;
            background-color: #${color.code};
            border-radius: 100%;
            //position: absolute;
            //top: 2px;
            //left: 2px;
          }
        `}</style>
      </button>
    );
  }
}
