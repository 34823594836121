import React from 'react';

function PaletteBack({ opened, transparent, onClosePalette }) {
  return (
    <span
      className={`${opened && 'opened'} ${transparent && 'transparent'}`}
      onClick={() => {
        if (transparent) onClosePalette();
      }}
    >
      <style jsx>{`
        span {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          opacity: 0;
          background: rgba(0, 0, 0, 0.2);
          transition: 0.7s opacity ease-in-out;
        }
        span.opened {
          opacity: 1;
        }
        span.transparent {
          background: none;
          z-index: 2;
        }
      `}</style>
    </span>
  );
}

export default PaletteBack;
