import React, { Component } from 'react';

import GLTransition from 'react-gl-transition/lib/GLTransition';
import GLTransitions from 'gl-transitions';
import { TweenLite } from 'gsap';

import { Surface } from 'gl-react-dom';
/*
const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};*/

export default class WallGl extends Component {
  constructor(props) {
    super(props);
    const toObj = props.walls.filter((e) => e.color === props.activeColor)[0];
    const to = require(`../../assets/textures/${toObj.name}`);
    const from = require(`../../assets/textures/${props.walls[0].name}`);
    this.state = {
      progress: 0,
      from: from,
      to: to,
    };
  }

  componentDidMount() {
    //if (this.props.dir === "left") {
    //this.startTransition(1.3, 4);
    //}
    this.startTransition(1.4, 6);
  }

  componentDidUpdate(prevProps) {
    /*if (this.props.isReady && !prevProps.isReady) {
      this.startTransition(1.4, 6);
    }*/
    if (this.props.activeColor !== prevProps.activeColor) {
      this.startTransition(0, 3);

      const fromObj = this.props.walls.filter(
        (e) => e.color === prevProps.activeColor
      )[0];
      const from = require(`../../assets/textures/${fromObj.name}`);

      const toObj = this.props.walls.filter(
        (e) => e.color === this.props.activeColor
      )[0];
      const to = require(`../../assets/textures/${toObj.name}`);

      this.setState({ from, to, progress: 0 });
    }
  }

  startTransition(delay, time) {
    let animProgress = {
      progress: 0.0,
    };
    TweenLite.to(animProgress, time, {
      progress: 1.0,
      //ease: Power2.easeInOut,
      delay: delay,
      onUpdate: () => {
        this.setState({ progress: animProgress.progress });
      },
    });
  }

  render() {
    const { dir } = this.props;
    const { progress, from, to } = this.state;

    const transition = GLTransitions.filter((e) => e.name === 'perlin')[0];

    return (
      <div className={dir}>
        {to && (
          <Surface width={1600} height={900} pixelRatio={1}>
            <GLTransition
              progress={progress}
              from={from}
              to={to}
              transition={transition}
            />
          </Surface>
        )}
        <style jsx>{`
          div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
          }
          div.top {
            z-index: 0;
          }
          div.right {
            z-index: 2;
            //margin-top: -2px;
          }
          div.back {
            z-index: 1;
          }
          div.left {
            z-index: -1;
          }
        `}</style>
      </div>
    );
  }
}
