const importTransAssets = (list, paths) => {
  const req = require.context(
    '../assets/',
    true,
    /\.(png|gif|jpe?g|svg|mp4|ico)$/
  );

  if (paths) {
    paths.map((e, i) => {
      let value;
      switch (e.length) {
        case 2:
          value = list[e[0]][e[1]];
          list[e[0]][e[1]] = req(`${value}`);
          break;
        case 3:
          value = list[e[0]][e[1]][e[2]];
          list[e[0]][e[1]][e[2]] = req(`${value}`);
          break;
        case 4:
          value = list[e[0]][e[1]][e[2]][e[3]];
          list[e[0]][e[1]][e[2]][e[3]] = req(`${value}`);
          break;
        default:
          break;
      }

      return e;
    });
  }
  return list;
};

export { importTransAssets };
