import React, { Component } from 'react';

import * as THREE from 'three';

export default class DecorationDog extends Component {
  componentDidMount() {
    this.initMask();
  }

  initMask() {
    const { width, height } = this.props;
    var lastUpdate;
    var camera, scene, renderer;
    var uniforms;
    var videoTexture;
    let container = this.container;
    let video = this.video;

    function init(showStats) {
      // basic setup
      camera = new THREE.Camera();
      camera.position.z = 1;
      scene = new THREE.Scene();

      // load video
      videoTexture = new THREE.VideoTexture(video);
      videoTexture.minFilter = THREE.LinearFilter;
      videoTexture.magFilter = THREE.LinearFilter;
      videoTexture.format = THREE.RGBFormat;

      // shader stuff
      uniforms = {
        time: { type: 'f', value: 1.0 },
        texture: { type: 'sampler2D', value: videoTexture },
      };
      var material = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: document.getElementById('vertexShader').textContent,
        fragmentShader: document.getElementById('fragmentShader').textContent,
        transparent: true,
      });
      lastUpdate = new Date().getTime();

      // put it together for rendering
      var geometry = new THREE.PlaneBufferGeometry(2, 2);
      var mesh = new THREE.Mesh(geometry, material);
      mesh.scale.setScalar(0.8);
      scene.add(mesh);
      renderer = new THREE.WebGLRenderer({
        alpha: true,
      });
      //    renderer.setPixelRatio(window.devicePixelRatio / parseFloat(resolution));
      renderer.setPixelRatio(2);
      container.appendChild(renderer.domElement);

      // event listeners
      /*document.getElementById("play-button").addEventListener("click", e => {
        video.play();
      });
      document.getElementById("stop-button").addEventListener("click", e => {
        video.pause();
      });*/
      onWindowResize();
      window.addEventListener('resize', onWindowResize, false);
      video.play();
    }

    // events
    function onWindowResize(evt) {
      renderer.setSize(width, height);
    }
    /*
    function onResolutionChange(evt) {
      var newResolutionScale = parseFloat(evt.target.value);
      renderer.setPixelRatio(window.devicePixelRatio / newResolutionScale);
    }*/
    function animate() {
      var currentTime = new Date().getTime();
      var timeSinceLastUpdate = currentTime - lastUpdate;
      lastUpdate = currentTime;
      requestAnimationFrame(animate);
      render(timeSinceLastUpdate);
    }
    function render(timeDelta) {
      uniforms.time.value += timeDelta ? timeDelta / 1000 : 0.05;
      renderer.render(scene, camera);
    }

    // boot
    init(true);
    animate();
  }

  render() {
    const { video, type, width, height } = this.props;

    return (
      <div className={`container ${type}`} ref={(e) => (this.container = e)}>
        <video
          ref={(e) => (this.video = e)}
          src={require(`../../assets/decoration/${video}`)}
          loop
          muted
          autoPlay
        />
        <style jsx>{`
          .container {
            position: absolute;
            z-index: 1;
            width: ${width}px;
            height: ${height}px;
          }
          .dog {
            top: 600px;
            right: 300px;
          }
          .fish {
            top: 530px;
            right: 185px;
          }
          .woman {
            top: 375px;
            left: 640px;
          }
          .window {
            left: 408px;
            top: 113px;
          }
          video {
            display: none;
          }
        `}</style>
      </div>
    );
  }
}
